.sidebar {
  display: flex;
  flex-direction: column; /* Ensure the sidebar stacks its children vertically */
  height: 100vh; /* Make sure the sidebar takes the full height of the viewport */
  position: relative; /* Fix the sidebar position */
  width: 280px; /* Adjust width to include both company selection and channel list bar */

  .sidebar__company-selection-bar {
    width: 72px;
    padding: 14px;

    .sidebar__company-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
    }
  }

  .channel-list-bar {
    // display: flex;
    // flex-direction: column;
    // flex-grow: 1; /* Allow the channel list bar to take remaining height */
    width: 280px;
    overflow-y: auto; /* Enable vertical scrolling */
    padding-top: 80px;
    padding-bottom: 30px;

    .channel-list-bar__header {
      padding-inline: 16px;
      height: var(--header-height);
      flex-shrink: 0; /* Ensure header doesn't shrink */
    }

    .channel-list-bar__content {
      flex-grow: 1; /* Allow content to grow and take available space */
    }
  }
}
