.str-chat-channel-list {
  height: fit-content;
}

.str-chat__load-more-button {
  display: none;
}

.team-channel-list {
  width: 100%;

  .str-chat__avatar-fallback {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      var(--primary-color);
  }

  .team-channel-list__message {
    padding: 30px 16px;
  }

  .team-channel-list__message.loading {
    height: 115px;
  }

  .team-channel-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    margin-top: 10px;

    .team-channel-list__header__title {
      height: 16px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding: 4px 10px;
    }

    button {
      display: flex;
      align-items: center;
      padding-block: 4px;
    }
  }
}

.toggle-collapse-button {
  // margin-left: auto; /* Align the button to the right */
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #007bff; /* Customize color */
}

.toggle-collapse-button:hover {
  text-decoration: underline;
}
