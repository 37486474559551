html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

#chat-stream {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

.app__wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 100%;

  @media only screen and (max-width: 930px) {
    .sidebar {
      width: 100%;
      position: absolute;
      left: -100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .str-chat__thread,
    .pinned-messages__container {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1000;
      width: 100vw;
      max-width: 100%;
      background: white;
    }
  }
}

.sidebar {
  width: 280px;
}

.channel__container {
  height: 96%;
  width: 100%;
  min-width: 0;
  flex: 1;
}

.str-chat__thread,
.pinned-messages__container {
  width: 30%;
  min-width: 300px;
}
