@use '../utils';

.channel-preview {
  display: flex;
  align-items: center;
  width: 90%;
  // height: 37px;
  padding: 10px 16px;
  margin-top: 2px;

  &.selected {
    display: flex;
    align-items: center;
    // height: 37px;
  }
}

.channel-preview__item {
  display: flex;
  align-items: center;
  max-width: 220px;
  height: 100%;
  width: 100%;
  padding-left: 26px;

  p {
    @include ellipsis-text;
  }

  &.single .str-chat__avatar {
    margin-right: 12px;
  }

  &.multi .str-chat__avatar {
    margin-right: 0;
  }

  &.multi span:first-child {
    position: relative;
    z-index: 2;
    bottom: 6px;
  }

  &.multi div:nth-child(2) {
    position: relative;
    right: 10px;
    z-index: 1;
    margin-bottom: 0;
  }
}

.channel-preview__item_job {
  display: flex;
  max-width: 220px;
  height: 100%;
  width: 100%;
  padding-left: 26px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  position: relative;

  p {
    @include ellipsis-text;
  }

  &.single .str-chat__avatar {
    margin-right: 12px;
  }

  &.multi .str-chat__avatar {
    margin-right: 0;
  }

  &.multi span:first-child {
    position: relative;
    z-index: 2;
    bottom: 6px;
  }

  &.multi div:nth-child(2) {
    position: relative;
    right: 10px;
    z-index: 1;
    margin-bottom: 0;
  }
}

.channel-preview__unread-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px 5px;
  font-size: 0.75em;
  margin-left: auto;
  position: absolute;
  top: 5px;
  left: -5px;
}

.bold {
  font-weight: var(--font-weight-bold);
}
