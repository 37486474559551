@use '../utils';

.str-chat__message-team-actions {
  border-radius: 100px;
  background: white;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);

  > span {
    cursor: pointer;

    svg {
      fill: #000;
      opacity: 0.5;
    }

    &:hover svg {
      opacity: 1;
    }
  }

  .str-chat__message-actions-box-button {
    @include reset-button-style;
  }

  .str-chat__message-actions-box {
    border-radius: 7px;
    padding: 3px;
  }

  .str-chat__message-actions-list-item-button {
    padding: 5px;
  }
}
