.str-chat {
  --str-chat__channel-list-background-color: #ebe8e8;
}

.team-channel-list {
  .str-chat__avatar-fallback {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      var(--primary-color);
  }

  .team-channel-list__message {
    color: black;
  }

  .team-channel-list__header {
    .team-channel-list__header__title {
      font-size: 13px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.9);
      font-weight: var(--font-weight-bold);
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
}

.str-chat-channel-list_empty {
  font-size: 12px;
  padding: 0px 20px;
}
