@mixin highlighted-button {
  background: rgba(0, 0, 0, 0.2);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: var(--font-weight-bold);
}

.channel-preview {
  background: rgba(0, 0, 0, 0.05);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none;
  cursor: pointer;

  &.selected {
    @include highlighted-button;
  }

  &:hover {
    @include highlighted-button;
  }
}

.channel-preview__item {
  font-size: 14px;
  color: black;
}

.channel-preview__name {
  font-size: 14px;
  color: black;
  display: flex;
}
.channel-preview__date {
  font-size: 12px;
  color: #333333;
  font-weight: var(--font-weight-normal);
  display: flex;
}
