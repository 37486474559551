@use '../utils';

.channel-search__container {
  position: absolute;
  top: 0px;
  justify-content: center;
  align-items: center;
  padding: 16px 8px 8px;
  width: 278px;
}

.channel-search__input__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 0;

  input {
    min-width: 0;
  }

  .channel-search__input__icon {
    width: 32px;
    display: flex;
    justify-content: center;
  }
}

.channel-search__results {
  position: absolute;
  height: fit-content;
  width: 300px;
  z-index: 10;
  left: 230px;
  top: 16px;
  padding: 10px;

  .channel-search__results-header {
    width: fit-content;
    display: flex;
    align-items: center;
    margin-left: 12px;

    i {
      margin-left: 12px;
    }
  }

  .channel-search__result-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    gap: 8px;

    .result-hashtag {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 28px;
    }

    .channel-search__result-text {
      @include utils.ellipsis-text;
      width: 100%;
    }
  }
}
